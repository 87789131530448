import { useEffect, useRef } from 'react';
import { DateTimeFormater } from "../../../services/Helper";

const ChatHistory = ({ messages, isChatLoading, isNoMessage, NoMessageText }) => {
    const chatContainerRef = useRef(null);
    const prevMessagesRef = useRef([]);

    useEffect(() => {
        if (chatContainerRef.current && prevMessagesRef.current.length !== messages.length) {
            chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
        }
        prevMessagesRef.current = messages; 
    }, [messages]);


    return (
        <>
            <div className="row g-3 h-fix" ref={chatContainerRef}>
                {!isChatLoading && messages && 
                    messages.map((msg, index) => (
                        <div key={index} className="col-lg-12" style={{ marginTop: '10px' }}>
                            <div className={`d-flex align-items-center gap-3 justify-content-${msg.sender !== 'pillpharm' ? 'start' : 'end'}`}>
                                <div>
                                    <p className={`m-0 ${msg.sender !== 'pillpharm' ? '' : 'text-end'}`}>
                                        {msg.sender !== 'pillpharm' ? msg.sender : 'Pillpharm'}
                                    </p>
                                    <div className={`${msg.sender !== 'pillpharm' ? 'left-block text-white' : 'right-block'} fs-14`}>
                                        <span>{msg.body}</span>
                                        <p className={`m-0 mt-1 time ${msg.sender !== 'pillpharm' ? 'text-end' : 'text-dark text-end'}`}>
                                            <span>{DateTimeFormater(msg.creationDate)}</span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))
                }
            </div>
            {isNoMessage &&
                <div className='col-12 text-center' >
                    <p className="text-danger">{NoMessageText === '' ? 'No message found':NoMessageText}</p>
                </div>
            }
        </>
    );
};

export default ChatHistory;
