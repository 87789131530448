function truncateText(text, wordLimit) {
    if (typeof text !== 'string') return '';


    if (text.length > wordLimit) {
        return text.slice(0, wordLimit) + '...';
    }
    return text;
}
const DateFormater = (dateString) => {

    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.toLocaleString('default', { month: 'long' });
    const year = date.getFullYear();
    return `${day} ${month}, ${year}`;

}
const DateTimeFormater = (date) => {
    // Ensure date is a Date object
    const d = new Date(date);

    // Get individual date and time components
    const year = d.getFullYear();
    let month = d.getMonth() + 1;
    let day = d.getDate();
    let hours = d.getHours();
    let minutes = d.getMinutes();

    // Add leading zeros if necessary
    month = month < 10 ? `0${month}` : month;
    day = day < 10 ? `0${day}` : day;
    hours = hours < 10 ? `0${hours}` : hours;
    minutes = minutes < 10 ? `0${minutes}` : minutes;

    return `${day}/${month}/${year}  ${hours}:${minutes}`;
};
const UkDateFormater = (date) => {

    const d = new Date(date);
    const year = d.getFullYear();
    let month = d.getMonth() + 1;
    let day = d.getDate();
    month = month < 10 ? `0${month}` : month;
    day = day < 10 ? `0${day}` : day;
    return `${day}/${month}/${year}`;

};

const getStatus = (status) => {
    switch (status) {
      case "NOT_STARTED":
        return { text: "ERROR", class: "alert-danger" }; // RED
      case "SEND_TO_RM":
          return { text: "READY FOR RM", class: "alert-custom" }; // RED
      case "PROCESSED":
        return { text: "SENT TO RM", class: "alert-primary" }; // BLUE
      case "ASSESSMENT_REQUIRED":
        return { text: "ASSESSMENT INCOMPLETE", class: "alert-info" }; // GREY
      case "PENDING":
        return { text: "PENDING P APPROVAL", class: "alert-warning" }; // ORANGE
      case "SHIPPED":
        return { text: "DISPATCHED", class: "alert-success" }; // GREEN
      default:
        return { text: status, class: "alert-secondary" }; // Default case
    }
};

export {truncateText, DateFormater, DateTimeFormater,UkDateFormater, getStatus };